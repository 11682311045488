import React, { FunctionComponent, useContext, useState } from 'react';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import { BsLayerBackward } from 'react-icons/bs';
import { RxLayers } from 'react-icons/rx';
import { HiCursorClick } from 'react-icons/hi';
import InspectionPanelContext from 'App/InspectionPanel/InspectionPanelContext';
import { AiOutlineEye } from 'react-icons/ai';
import { MdOutlineBarChart, MdOutlineMultilineChart } from 'react-icons/md';

interface Props {}

const JOYRIDE_STEPS: Step[] = [
    {
        content: (
            <>
                <h2>Level-Specific Tools</h2>
                Some tools are only applicable to entities
                <br />
                on a certain architecture levels.&nbsp;
                <RxLayers />
                <br style={{ marginBottom: '0.25em' }} />
                Descend into a single model to reveal them.&nbsp;
                <BsLayerBackward />.
            </>
        ),
        placement: 'center',
        target: 'body',
    },
    {
        content: (
            <div>
                <h2>Descend Into Model</h2>
                Double-click to descend into a model.&nbsp;
                <HiCursorClick />
            </div>
        ),
        locale: { next: 'Descend!' },
        placement: 'right',
        target: '#l3-model-component-45ad4cd7-df1a-4525-9adb-355f0a125f6e',
    },
    {
        content: (
            <div>
                <h2>Layer-Specific Tools</h2>
                When inspecting a single model, individual layers
                <br />
                and their contained entities can be inspected. <AiOutlineEye />
                <br style={{ marginBottom: '0.25em' }} />
                These tools visualize the layer&apos;s activations.&nbsp;
                <MdOutlineBarChart />
            </div>
        ),
        placement: 'left',
        target: '#toolbox-group-a4876d361ea451e50e995851a8ab26f9',
    },
    {
        content: (
            <div>
                <h2>Customized Tools</h2>
                This is a highly customized tool, allowing to debug the value distribution of a latent layer.&nbsp;
                <MdOutlineMultilineChart />
                <br style={{ marginBottom: '0.25em' }} />
                <b>Did you know that iNNspector is built to be extensible with your custom tools?</b>&nbsp;
                <MdOutlineBarChart />
                <br style={{ marginBottom: '0.25em' }} />
                If you want to know more, please&nbsp;
                <a href="mailto:thilo.spinner@uni-konstanz.de?subject=Contacting%20You%20About%20iNNspector&body=Hi!%0D%0A%0D%0AI%20just%20participated%20in%20the%20iNNspector%20user%20study%20and%20am%20interested%20in%20learning%20more%20about%20the%20tool.">
                    contact us!
                </a>
            </div>
        ),
        placement: 'left',
        target: '#tool-87b2a1be-6ce8-427c-b51d-17292cf4686b',
    },
];

const JoyrideOnboarding: FunctionComponent<Props> = ({}: Props) => {
    const [stepIndex, setStepIndex] = useState(0);
    const [run, setRun] = useState(true);

    const { descendLofa, ascendLofa } = useContext(InspectionPanelContext);

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { action, index, type } = data;

        if (
            (type === 'step:after' || type === 'tour:end') &&
            (action === 'close' || action === 'skip' || action === 'next')
        ) {
            if (stepIndex === 1) {
                descendLofa('45ad4cd7-df1a-4525-9adb-355f0a125f6e');
            }
            setTimeout(() => {
                setStepIndex(index + 1);
            }, 100);
        }

        if (type === 'step:after' && action === 'prev') {
            if (stepIndex === 2) {
                ascendLofa();
            }
            setTimeout(() => {
                setStepIndex(index - 1);
            }, 100);
        }

        if (type === 'tour:end') {
            setRun(false);
        }
    };

    // const handleLayerClick = () => {
    //     // Move to step 3
    //     setStepIndex(2);
    // };
    //
    // // Dirty hack to attach the click handler to the layer element.
    // useEffect(() => {
    //     const layerElement = document.getElementById('#l3-model-component-2');
    //     if (layerElement) {
    //         layerElement.addEventListener('click', handleLayerClick);
    //     }
    //
    //     return () => {
    //         if (layerElement) {
    //             layerElement.removeEventListener('click', handleLayerClick);
    //         }
    //     };
    // }, []);

    return (
        <>
            <Joyride
                continuous
                callback={handleJoyrideCallback}
                stepIndex={stepIndex}
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                }}
                locale={{
                    last: 'Finish Tutorial',
                }}
                steps={JOYRIDE_STEPS}
                run={run}
            />
        </>
    );
};

export default JoyrideOnboarding;
