import * as React from 'react';
import styled from 'styled-components';
import InspectionPanel from 'App/InspectionPanel/InspectionPanel';
import Header from 'App/Header';
import WidgetContextProvider from 'App/WidgetContextProvider';
import SelectionContextProvider from 'App/SelectionContextProvider';
import SettingsPanel from 'App/SettingsPanel/SettingsPanel';
import ToolboxPanel from './ToolboxPanel/ToolboxPanel';
import FilterContextProvider from 'App/FilterContextProvider';
import ToolContext from 'App/ToolContext';
import { useKeyPressEvent } from 'react-use';
import ClassSelectionContextProvider from 'App/ClassSelectionContextProvider';
import InspectionPanelContextProvider from 'App/InspectionPanel/InspectionPanelContextProvider';
import InterestingnessContextProvider from 'App/InterestingnessContextProvider';
import GroupsContext from 'App/GroupsContext';
import JoyrideOnboarding from 'App/JoyrideOnboarding';

const MainViewContainer = styled.div`
    background-color: #eee;
    min-height: 100vh;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
`;

const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
`;

interface Props {}

const MainView: React.FunctionComponent<Props> = (props: Props) => {
    const { groups } = React.useContext(GroupsContext);
    const { unsetActiveTool } = React.useContext(ToolContext);

    useKeyPressEvent('Escape', unsetActiveTool);

    return (
        <MainViewContainer>
            <SelectionContextProvider>
                <InspectionPanelContextProvider>
                    <WidgetContextProvider>
                        <ClassSelectionContextProvider allClasses={groups[0].models[0].info.classes}>
                            <JoyrideOnboarding />
                            <Header />
                            <HorizontalContainer>
                                <FilterContextProvider>
                                    <InterestingnessContextProvider groups={groups}>
                                        <SettingsPanel /> <InspectionPanel />
                                    </InterestingnessContextProvider>
                                </FilterContextProvider>
                                <ToolboxPanel />
                            </HorizontalContainer>
                        </ClassSelectionContextProvider>
                    </WidgetContextProvider>
                </InspectionPanelContextProvider>
            </SelectionContextProvider>
            {/*</MainViewTab>*/}
        </MainViewContainer>
    );
};

export default MainView;
